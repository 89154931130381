import { Injectable } from '@angular/core';

@Injectable()
export class RememberService {
  public unitsDashboardGroup = null;
  public unitsDashboardLocation = null;
  public unitsDashboardViewMode = null;

  public reportsDashboardGroup = null;
  public reportsDashboardLocation = null;
  public reportsDashboardViewMode = null;

  constructor() {}

  public clear(): void {
    this.unitsDashboardGroup = null;
    this.unitsDashboardLocation = null;
    this.unitsDashboardViewMode = null;

    this.reportsDashboardGroup = null;
    this.reportsDashboardLocation = null;
    this.reportsDashboardViewMode = null;
  }
}
