import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Observer } from 'rxjs';


import { EnvService } from './env.service';

@Injectable()
export class ApiService {

    constructor(
        private http: HttpClient,

        private envService: EnvService,
        private router: Router
    ) {}

    private constructUrl(route: string, getParams: Object = null, requiresAuth: boolean = true): string {
        while(!this.envService.env){
            console.log("waiting for env");
        }
        let url = this.envService.env.BASE_API_URL + route;
  
        // Append get params to url
        if(getParams != null) {
            for(var property in getParams) {
                url += '&' + property + '=' + getParams[property];
            }
        }
        
        return url;
    }

    public request(route: string, controlLoader: boolean = true, getParams: Object = null, postParams: Object = null, method: string = 'get', requiresAuth: boolean = true): Observable<any> {
  

        const url = this.constructUrl(route, getParams, requiresAuth);

        // Create observable
        let httpObservable: Observable<any>;
        switch(method.toLowerCase()) {
            case 'get':
                httpObservable = this.http.get(url);
                break;
            case 'post':
                httpObservable = this.http.post(url, postParams);
                break;
            case 'put':
                httpObservable = this.http.put(url, postParams);
                break;
            case 'delete':
                httpObservable = this.http.delete(url);
                break;
            default:
                break;
        }

        // Create observable to return
        return Observable.create((observer: Observer<any>) => {
            httpObservable.subscribe(
                (response: Response) => {
              
                    observer.next(response);
                },
                error => {
           

                    // Redirect to 404
                    if(error.status == 404) {
                        this.router.navigate(['/pages', '404']);
                    }

              

                    observer.error(error);
                }
            );
        });
    }
}
