import {Component} from '@angular/core';

import { navItems } from '../../_nav';
import {  EnvService } from '../../shared/services';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {

  constructor(

    public envService:EnvService
  ) {}
  public sidebarMinimized = false;
  public navItems = navItems;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
